import {EMPTY_CONTEXT, isSelectContextStep, WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {Board} from "../../../model/Board";
import {Graph, Node, NodeType} from "../../../model/Graph";
import {dddActionFromNode} from "../context/ddd-action";
import {featureContextFromNode} from "../context/feature-context";
import {informationContextFromNode} from "../context/information-context";
import {isPartOfPolicyContext, policyContextFromNode} from "../context/policy-context";
import {uiContextFromNode} from "../context/ui";
import {WizardContext} from "../context/wizard-context";
import {isAggregateEvent, isDddActionEvent} from "../event/is-aggregate-event";
import {isDddActionOrPolicyFeature} from "../feature/is-ddd-action-or-policy-feature";
import {isDddActionUi} from "../ui/is-ddd-action-ui";
import {isStateVo} from "../vo/is-state-vo";
import {isStateVoCandidate} from "../vo/is-state-vo-candidate";

export const determineWizardContextAndStep = (node: Node, board: Board, graph: Graph): {ctx: WizardContext, step: WizardStep | null} => {
  let newCtx: WizardContext = {...EMPTY_CONTEXT, board};
  let wizardStep: WizardStep | null = null;

  switch (node.getType()) {
    case NodeType.command:
      newCtx = dddActionFromNode(node, board);
      wizardStep = 'command';
      break;
    case NodeType.aggregate:
      newCtx = dddActionFromNode(node, board);
      wizardStep = 'aggregate';
      break;
    case NodeType.event:
      if (isDddActionEvent(node)) {
        wizardStep = 'dddEvent';
        newCtx = dddActionFromNode(node, board);
      } else if (isPartOfPolicyContext(node)) {
        wizardStep = 'policyEvent';
        newCtx = policyContextFromNode(node, board);
      } else {
        wizardStep = {step: "selectContext", contexts: ['dddAction', 'policy'], node};
      }
      break;
    case NodeType.ui:
      if(isDddActionUi(node)) {
        newCtx = dddActionFromNode(node, board);
      } else {
        newCtx = uiContextFromNode(node, board);
      }
      wizardStep = 'ui';
      break;
    case NodeType.document:
      if(isStateVoCandidate(node)) {
        newCtx = dddActionFromNode(node, board)
        wizardStep = 'state';
        break;
      }

      newCtx = informationContextFromNode(node, board);
      wizardStep = 'anyVO';
      break;
    case NodeType.feature:
      if(isDddActionOrPolicyFeature(node)) {
        newCtx = isPartOfPolicyContext(node)? policyContextFromNode(node, board) : dddActionFromNode(node, board);
      } else {
        newCtx = featureContextFromNode(node, board);
      }
      wizardStep = 'feature';
      break;
    case NodeType.policy:
      newCtx = policyContextFromNode(node, board);
      wizardStep = 'policy';
      break;
    case NodeType.externalSystem:
      if(isPartOfPolicyContext(node)) {
        newCtx = policyContextFromNode(node, board);
        wizardStep = 'policy';
      }
      break;
  }

  return {
    ctx: newCtx,
    step: wizardStep,
  }
}

export const initializeCodyGraphSuggestions = (node: Node, board: Board, graph: Graph): {ctx: WizardContext, step: WizardStep | null} => {

  resetCodySuggestCallbacks(graph);

  const {ctx, step} = determineWizardContextAndStep(node, board, graph);
  const newCtx = ctx;
  const wizardStep = step;

  if(isSelectContextStep(wizardStep)) {
    wizardStep.contexts.forEach(ctxName => {
      let possibleCtx: WizardContext = {...EMPTY_CONTEXT, board};
      let possibleStep: WizardStep | null = null;

      switch (ctxName) {
        case "dddAction":
          possibleCtx = dddActionFromNode(node, board);
          // @TODO: generic solution needed!
          possibleStep = 'dddEvent';
          break;
        case "policy":
          possibleCtx = policyContextFromNode(node, board);
          // @TODO: generic solution needed!
          possibleStep = 'policyEvent';
          break;
      }

      if(possibleStep) {
        possibleCtx.installCodyGraphSuggestions(possibleStep, possibleCtx, graph);
      }
    })
  } else {
    if(wizardStep) {
      newCtx.installCodyGraphSuggestions(wizardStep, newCtx, graph);
    }
  }

  return {
    ctx: newCtx,
    step: wizardStep,
  }
}

const resetCodySuggestCallbacks = (graph: Graph): void => {
  graph.setCodyUpCb(null);
  graph.setCodyDownCb(null);
  graph.setCodyLeftCb(null);
  graph.setCodyRightCb(null);
}
